export const MapGeneralites: any = {
  NE: "Nord Est",
  SE: "Sud Est",
  dept: "Département",
  alt: "Altitude",
  year_cons: "Année de construction",
  livable_surface_housing: "Surface habitable",
  livable_surface_building: "Surface habitable du bâtiment",
  nb_floors: "Nombre d'étages",
  avg_height_floor: "Hauteur moyenne sous plafond",
  type_bien: "Type bien",
};

export const MapLabelType: any = {
  type_de_bien: "Type de bien",
  type_enveloppe: "Type d'enveloppe",
  type_local_adjacent: "Type de local adjacent",
};

export const MappingMeasureUnit: any = {
  surface: "m2",
  size: "m",
  epaisseur: "cm",
};

export const MapValue: any = {
  type_de_bien: "Type de bien",
  type_enveloppe: "Type d'enveloppe",
  ext: "L'extérieur",
  type_local_adjacent: "Type de local adjacent",
  bloc_de_beton_plein: "Murs en blocs de béton pleins",
  bloc_de_beton_creux: "Murs en blocs de béton creux",
  pierre_de_taille: "Murs en pierre de taille et moellons",
  terre_stabilise: "Murs en pisé ou béton de terre stabilisé",
  pans_de_bois: "Murs en pans de bois",
  rondins: "Murs bois (rondins)",
  briques_pleines: "Murs en briques pleines",
  briques_creuses: "Murs en briques creuses",
  bloc_beton_pleins: "Murs en blocs de béton pleins",
  bloc_beton_creux: "Murs en blocs de béton creux",
  beton: "Murs en béton",
  terre_cuite_alveolaire: "Brique terre cuite alvéolaire",
  beton_cellulaire: "Mur en béton cellulaire",
  sandwich: "Murs sandwich béton/isolant/béton (sans isolation rapportée)",
  ossature: "Murs en ossature bois",
  pierre_de_taille_seul_matériau: " Constitués d'un seul matériau",
  pierre_de_taille_tout_venant: "Avec remplissage tout venant",
  pans_de_bois_sans_tout_venant: "Sans remplissage tout venant",
  pans_de_bois_avec_tout_venant: "Avec remplissage tout venant",
  blocs_de_beton_pleins: "Murs en blocs de béton pleins",

  murs_pierre_taille_moellons_constitue_seul_materiau:
    "Murs constitués d’un seul matériau",
  murs_pierre_taille_moellons_remplissage_tout_venant:
    "Murs avec remplissage tout venant",
  murs_pierre_taille_moellons_inconnu: "Inconnu",
  murs_pans_bois_sans_remplissage_tout_venant: "Sans remplissage tout venant",
  murs_pans_avec_sans_remplissage_tout_venant: "Avec remplissage tout venant",
  murs_pise_beton_terre_stabilise: "Murs en pisé ou béton de terre stabilisé",
  murs_bois_rondins: "Murs bois (rondins)",
  murs_briques_pleines_simples: "simples",
  murs_briques_pleines_doubles: "doubles avec lame d’air",
  murs_briques_creuses: "Murs en briques creuses",
  murs_blocs_beton_pleins: "Murs en blocs de béton pleins",
  murs_blocs_beton_creux: "Murs en blocs de béton creux",
  murs_beton_banche: "banché",
  murs_beton_machefer: "de mâchefer",
  brique_terre_cuite_alveolaire: "Brique terre cuite alvéolaire",
  murs_beton_cellulaire: "Mur en béton cellulaire",
  murs_sandwich: "Murs sandwich béton/isolant/béton (sans isolation rapportée)",
  murs_ossature_bois_isolant_remplissage: "avec isolant en remplissage",
  murs_ossature_bois_remplissage_tout_venant: "avec remplissage tout venant",
  murs_ossature_bois_sans_remplissage: "sans remplissage",
};

export const MAP_COLOR_DPE: {
  [key: string]: string;
} = {
  A: "#189c44",
  B: "#4FAA05",
  C: "#CCD600",
  D: "#FFEE6C",
  E: "#FFC661",
  F: "#FF8300",
  G: "#FF3238",
};

export const CONTROL_BUTTON = {
  CONTROL: "controls_apply",
  BATCH: "records_to_atlas",
  REINIT: "reinit",
  DEPLOY: "deploy",
};
