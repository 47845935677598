import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Tabs, TabsProps } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { ReactComponent as IconPencil } from "assets/images/pencil.svg";
import { ReactComponent as IconWall } from "assets/images/renovation/wall.svg";
import { ReactComponent as IconAir } from "assets/images/renovation/air-conditioner.svg";
import { ReactComponent as IconDoor } from "assets/images/renovation/door.svg";
import { ReactComponent as IconHouse } from "assets/images/renovation/house.svg";
import { ReactComponent as IconWater } from "assets/images/renovation/water.svg";
import { ReactComponent as IconRise } from "assets/images/renovation/rise.svg";
import { ReactComponent as IconEuro } from "assets/images/renovation/euro.svg";
import { ReactComponent as IconBar } from "assets/images/renovation/bar.svg";
import { MAP_COLOR_DPE } from "constants/index";

import "styles/dashboard.scss";
import { useChainlitMutation } from "../../mutations/useChainlitMutation";
import { useGetReliabilityQuery } from "../../queries/useGetReliabilityQuery";

interface Props {
  dataSuggestion: any;
  id: string;
  openChainLit: () => void;
}

const MAP_ICON: any = {
  wall: IconWall,
  ceiling: IconHouse,
  heating: IconAir,
  doorswindows: IconDoor,
  hotwater: IconWater,
};

const SuggestionRenovation: FC<Props> = ({
  dataSuggestion,
  id,
  openChainLit,
}) => {
  const [tab, setTab] = useState("");

  const selectedTabContent = useMemo(() => {
    return dataSuggestion.find((item: any) => item.iter === tab);
  }, [dataSuggestion, tab]);

  const { mutateAsync } = useChainlitMutation();
  const { data: reliabilities } = useGetReliabilityQuery(id);

  const handleopenChainlit = async () => {
    await mutateAsync({
      ref_ademe: id,
      from: "control_renovation",
      from_details: {},
    });
    openChainLit();
  };

  const tabMapItems: TabsProps["items"] = dataSuggestion.map(
    (tabContent: any) => ({
      key: tabContent.iter,
      label: `Solution ${tabContent.iter}${
        tabContent.recommended ? " (recommandée)" : ""
      }`,
      children: (
        <div>
          <h4 className="font-bold text-[32px] mb-6">
            Solution {tabContent.iter}
            {tabContent.recommended ? " recommandée par checkDPE" : ""}
          </h4>

          <div className="flex flex-col gap-y-6">
            {!_.isEmpty(tabContent.projects) &&
              tabContent.projects.map((item: any, index: number) => {
                const Icon = MAP_ICON[item.object];
                return (
                  <div
                    key={index}
                    className={classNames("suggestion-item", {
                      active: item.hightlight,
                    })}
                  >
                    <div className="flex items-center justify-between flex-wrap gap-y-2">
                      <p className="flex items-center gap-x-2.5 text-[20px] font-bold">
                        <Icon />
                        <span>{item.title}</span>
                      </p>

                      <div className="flex items-center gap-x-6">
                        <div className="flex items-center">
                          <span className="font-semibold text-[16px] mr-1">
                            Gain
                          </span>
                          {[...new Array(4)].map((__, index) => (
                            <IconRise
                              key={index}
                              className={classNames("icon-stat", {
                                "icon-stat--active": item.gain >= index + 1,
                              })}
                            />
                          ))}
                        </div>

                        <div className="flex items-center">
                          <span className="font-semibold text-[16px] mr-1">
                            Prix
                          </span>
                          {[...new Array(4)].map((__, index) => (
                            <IconEuro
                              key={index}
                              className={classNames("icon-stat", {
                                "icon-stat--active": item.cost >= index + 1,
                              })}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    {!_.isEmpty(item.description) &&
                      item.description.map((desc: string, index: number) => (
                        <p className="text-[20px] mt-1" key={index}>
                          {desc}
                        </p>
                      ))}
                  </div>
                );
              })}
          </div>
        </div>
      ),
    })
  );

  const mapColor = (value: string) => {
    switch (value) {
      case "error":
        return "bg-red-400";
      case "warning":
        return "bg-orange-400";
      case "info":
        return "bg-green-400";
    }
  }

  useEffect(() => {
    if (!_.isEmpty(dataSuggestion)) {
      const activeTab = dataSuggestion.find((item: any) => item.recommended);
      setTab(activeTab.iter);
    }
  }, [dataSuggestion]);

  return (
    <Card className="mb-3">
      <div className="energy-home__suggestion-renovation">
        <div className="flex items-center justify-between mb-3">
          <h3 className="flex items-center gap-x-2.5 font-bold text-[28px] lg:text-[40px] leading-[48px] mb-3">
            <IconPencil className="flex-shrink-0" />
            <span>Les travaux pour améliorer mon DPE</span>
          </h3>

          <InfoCircleOutlined style={{ fontSize: 24 }} />
        </div>

        <div className="flex items-center mb-3 justify-between">
          <div>
            <h5>Scénario proposé par CheckDPE</h5>
            <div className="flex items-center justify-between">
              <div className="text-[18px]">
                Vous souhaitez bénéficier d'une proposition de travaux adaptée à
                votre projet
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-x-2.5 bg-bgPrimary text-white font-bold px-3 py-1 rounded-lg cursor-pointer lg:px-6"
            onClick={handleopenChainlit}
          >
            <span className="xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] xs:text-[12px]">
              Démarrer mon projet avec checkDPE
            </span>
            <IconBar className="lg:w-14 lg:h-14 w-12 h-12 md:w-8 md:h-8 sm:w-6 sm:h-6" />
          </div>
        </div>

        <div className="mb-5">
          <h5>Les scénarios de mon DPE</h5>
          <div className="flex items-center gap-2">
            <div className="text-[18px]">Qualité des scénarios proposés</div>
            <div className={`w-4 h-4 mt-1 rounded-full ${mapColor(reliabilities?.reliability.type || 'info')}`}></div>
          </div>

          <ul className="mt-2 list-disc pl-5">
            {reliabilities?.reliability?.issues?.details?.map(
              (reliability, index) => (
                <li key={index}>
                  <div className="text-[16px]">{reliability.detail}</div>
                  <div className="flex items-center justify-between">
                    <div className="text-[16px]">{reliability.label}</div>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>

        <div className="energy-home__suggestion-renovation__tabs">
          <Row gutter={[24, 24]}>
            <Col
              span={24}
              order={2}
              lg={{
                span: 15,
                order: 2,
              }}
              xl={{
                span: 17,
                order: 1,
              }}
            >
              <Tabs
                centered
                activeKey={tab}
                items={tabMapItems}
                onChange={setTab}
              />
            </Col>
            <Col
              span={24}
              order={1}
              lg={{
                span: 9,
                order: 2,
              }}
              xl={{
                span: 7,
                order: 2,
              }}
            >
              {selectedTabContent && (
                <div className="energy-home__montant">
                  <p className="flex items-center font-bold text-[24px] gap-x-1">
                    <IconEuro />
                    Montant des travaux
                  </p>
                  <p className="text-[20px] pl-7">
                    Entre {selectedTabContent.estimated_cost[0]}€ et{" "}
                    {selectedTabContent.estimated_cost[1]}€
                  </p>

                  <p className="flex items-center font-bold text-[24px] gap-x-1 mt-4">
                    <IconRise />
                    Passez de la lettre
                  </p>

                  <div className="flex items-center gap-x-[20px] pl-7 mt-2">
                    <div
                      className="montant-label"
                      style={{
                        background:
                          MAP_COLOR_DPE[
                            _.get(selectedTabContent, "gain.0", "")
                          ],
                      }}
                    >
                      {_.get(selectedTabContent, "gain.0", "")}
                    </div>
                    <span className="text-[32px]" style={{ color: "#414141" }}>
                      à
                    </span>
                    <div
                      className="montant-label"
                      style={{
                        background:
                          MAP_COLOR_DPE[
                            _.get(selectedTabContent, "gain.1", "")
                          ],
                      }}
                    >
                      {_.get(selectedTabContent, "gain.1", "")}
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default SuggestionRenovation;
