import baseApi from 'services/api/base.api';
import { useMutation } from '@tanstack/react-query';
import { paths } from 'services/api/paths';
import { AccountData } from 'types/AccountData.type';

export const useAccountMutation = () => {
    return useMutation({
        mutationFn: async (updateData: AccountData) => await baseApi.post(paths.getAccount(), updateData),
        retry: 0,
    });
};
