import { useQuery } from "@tanstack/react-query";
import API from "../services/api";
import { ReliabilityType } from '@/types/Reliability.type';

export const useGetReliabilityQuery = (query: string) => {
  return useQuery<ReliabilityType>({
    queryKey: ["reliability"],
    queryFn: async () => await API.mock.getReliability(query),
    enabled: !!query,
  });
};