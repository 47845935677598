import { useState } from "react";
import { Button, Form, FormProps, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { RightOutlined } from "@ant-design/icons";

import { cognitoUserPool } from "config"; // Import your Cognito User Pool configuration
import { EmailRegex } from "constants/regex";
import PAGES from "routes/constants";
import Logo from "assets/images/logo.png";
import { setUserInfo, setUserToken } from "services/storages/userStorage";

import "styles/login.scss";

function LoginPage() {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleLogin: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values);
    try {
      const user = new CognitoUser({
        Username: values.email,
        Pool: cognitoUserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: values.email,
        Password: values.password,
      });

      setLoading(true);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("Login successful:", data);
          setUserToken(data.getAccessToken().getJwtToken() || "");
          setUserInfo(data.getAccessToken().payload);
          setLoading(false);
          navigate(PAGES.home);
        },
        onFailure: (err) => {
          console.error("Login failed:", err);
          messageApi.error("Login failed: " + err.message);
          setLoading(false);
        },
      });
    } catch (e: any) {
      console.log("ERROR", e);
      setLoading(false);
    }
  };

  return (
    <div className="energy-login">
      <div className="energy-login__form">
        <img src={Logo} alt="logo" className="w-[203px] h-[175px] mx-auto" />
        <h1>Connectez-vous à votre espace checkDPE</h1>

        <Form
          form={form}
          name="login-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                pattern: EmailRegex,
                message: "Email is invalid",
              },
            ]}
            normalize={(value) => (value ? value.toLowerCase() : "")} // Convert to lowercase
          >
            <Input placeholder="Adresse email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input placeholder="Mot de Passe" type="password" />
          </Form.Item>

          <div className="text-right mb-4">
            <Link to={PAGES.forgotPassword} style={{ color: "black" }}>
              Mot de passe oublié
            </Link>
          </div>

          <Button htmlType="submit" className="btn-connect" loading={loading}>
            Connexion
          </Button>
        </Form>

        <Link
          to={PAGES.signup}
          className="flex items-center justify-center gap-x-1 underline font-bold text-[16px] mt-10"
          style={{ color: "black" }}
        >
          Je n'ai pas encore de compte{" "}
          <RightOutlined className="relative top-[1px]" />
        </Link>
      </div>

      {contextHolder}
    </div>
  );
}

export default LoginPage;
