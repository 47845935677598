import { useState } from "react";
import { Button, Form, FormProps, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { cognitoUserPool } from "config"; // Import your Cognito User Pool configuration
import PAGES from "routes/constants";
import {
  clearConfirmationEmail,
  getConfirmationEmail,
} from "services/storages/userStorage";

import "styles/confirmation-signup.scss";

function ConfirmationSignupPage() {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleConfirm: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values);
    try {
      setLoading(true);
      const email = getConfirmationEmail();

      const userData = {
        Username: email,
        Pool: cognitoUserPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmRegistration(
        values.code,
        true,
        function (err, result) {
          if (err) {
            console.error("Something wrong:", err);
            messageApi.error(
              "Something wrong: " + err.message || JSON.stringify(err)
            );
            setLoading(false);
            return;
          }

          setLoading(false);
          clearConfirmationEmail();
          navigate(PAGES.login);
          messageApi.success("Signup success");
        }
      );
    } catch (e: any) {
      console.log("ERROR", e);
      setLoading(false);
    }
  };

  return (
    <div className="energy-confirmation-signup">
      <div className="energy-confirmation-signup__form">
        <h1>Créer un compte checkDPE</h1>

        <Form
          form={form}
          name="confirmation-signup-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            code: "",
          }}
          onFinish={handleConfirm}
        >
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "Confirmation code is required",
              },
            ]}
          >
            <Input.OTP length={6} />
          </Form.Item>

          <Button htmlType="submit" className="btn-connect" loading={loading}>
            Confirm
          </Button>
        </Form>
      </div>

      {contextHolder}
    </div>
  );
}

export default ConfirmationSignupPage;
