import baseApi from "services/api/base.api";
import { useQuery } from "@tanstack/react-query";
import { AccountData } from "types/AccountData.type";
import { paths } from "services/api/paths";

export const useGetAccountQuery = (email: string) => useQuery<AccountData>({
    queryKey: ['email'],
    queryFn: async () => {
        return await baseApi.get(`${paths.getAccount()}?email=${email}`);
    },
    enabled: !!email,
    select(data) {
        return data
    },
});