import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId:
    process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
  ClientId:
    process.env.REACT_APP_COGNITO_CLIENT_ID as string
};

export const cognitoUserPool = new CognitoUserPool(poolData);
