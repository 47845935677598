import { useState } from "react";
import { Button, Form, FormProps, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { Steps } from "components/steps/steps";

import { cognitoUserPool } from "config"; // Import your Cognito User Pool configuration
import { EmailRegex } from "constants/regex";
import PAGES from "routes/constants";
import Logo from "assets/images/logo.png";
import { clearSignUpEmail, getSignupEmail, setConfirmationEmail } from "services/storages/userStorage";

import "styles/signup.scss";

function SignupPage() {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const userInfoAWS = cognitoUserPool.getCurrentUser();
  const signupEmail = getSignupEmail()

  const handleSignup: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values);
    try {
      setLoading(true);
      const email = values.email || signupEmail;
      const dataEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "email",
        Value: email,
      });
      cognitoUserPool.signUp(
        email,
        values.password,
        [dataEmail],
        [],
        (err, data) => {
          if (err) {
            console.error("Signup failed:", err);
            messageApi.error("Signup failed: " + err.message);
          } else {
            console.log("Signup success:", data);
            setConfirmationEmail(values.email);
            clearSignUpEmail()
            navigate(PAGES.account);
          }
          setLoading(false);
        }
      );
    } catch (e: any) {
      console.log("ERROR", e);
      setLoading(false);
    }
  };

  return (
    <div className="energy-signup">
      {signupEmail && !userInfoAWS && <Steps stepName="confirmation" />}
      <div className="energy-signup__form">
        {!signupEmail && <img src={Logo} alt="logo" className="w-[203px] h-[175px] mx-auto" />}
        <h1>Créez votre espace checkDPE</h1>

        <Form
          form={form}
          name="signup-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            email: signupEmail,
            password: "",
          }}
          onFinish={handleSignup}
        >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  pattern: EmailRegex,
                  message: "Email is invalid",
                },
              ]}
              normalize={(value) => (value ? value.toLowerCase() : "")}
            >
              <Input placeholder="Adresse email" />
            </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Form.Item>

          <Button htmlType="submit" className="btn-connect" loading={loading}>
            Continuer
          </Button>
        </Form>

        <Link
          to={PAGES.login}
          className="flex items-center justify-center gap-x-1 underline font-bold text-[16px] mt-10"
          style={{ color: "black" }}
        >
          J&apos;ai déjà un compte{" "}
          <RightOutlined className="relative top-[1px]" />
        </Link>
      </div>

      {contextHolder}
    </div>
  );
}

export default SignupPage;
