import baseApi, { HEADER_KEY } from "../api/base.api";
import createStorage from "./createStorage";

const instance = createStorage("energy");
const KEY_TOKEN = "token_api";
const KEY_CONFIRMATION_EMAIL = "leDPE_confirmation_email";
const KEY_USER_TOKEN = "user_token";
const KEY_USER_INFO = "user_info";
const KEY_SIGNUP_EMAIL = "signup_email"

export const getValueUser = (key: string) => instance.getValue(key);

export const setToken = (data: string) => {
  instance.setValue(KEY_TOKEN, data);
  baseApi.setDefaultHeader(HEADER_KEY.X_AUTHORIZTION, btoa(data));
};

export const getToken = () => instance.getValue(KEY_TOKEN);

export const clearToken = () => {
  instance.deleteKey(KEY_TOKEN);
  baseApi.removeHeaderAttr(HEADER_KEY.X_AUTHORIZTION);
};

export const getConfirmationEmail = () =>
  instance.getValue(KEY_CONFIRMATION_EMAIL);

export const setConfirmationEmail = (data: string) => {
  instance.setValue(KEY_CONFIRMATION_EMAIL, data);
};

export const clearConfirmationEmail = () => {
  instance.deleteKey(KEY_CONFIRMATION_EMAIL);
};

export const getUserToken = () => instance.getValue(KEY_USER_TOKEN);

export const setUserToken = (data: string) => {
  instance.setValue(KEY_USER_TOKEN, data);
  baseApi.setToken(data);
};

export const clearUserToken = () => {
  instance.deleteKey(KEY_USER_TOKEN);
  baseApi.removeHeaderAttr(HEADER_KEY.AUTHORIZTION);
};

export const getUserInfo = () => instance.getValue(KEY_USER_INFO);

export const setUserInfo = (data: any) => {
  instance.setValue(KEY_USER_INFO, data);
};

export const clearUserInfo = () => {
  instance.deleteKey(KEY_USER_INFO);
};

export const setSignupEmail = (data: string) => {
  instance.setValue(KEY_SIGNUP_EMAIL, data);
};

export const getSignupEmail = (): string | null => {
  return instance.getValue(KEY_SIGNUP_EMAIL);
};

export const clearSignUpEmail = () => {
  instance.deleteKey(KEY_SIGNUP_EMAIL);
};
