import { create } from "zustand";

export interface CommonType {
  isShowInsight: boolean;
  setIsShowInsight: (isShowInsight: boolean) => void;

  isLoadedPage: boolean;
  setIsLoadedPage: (isLoadedPage: boolean) => void;
}

const useCommonStore = create<CommonType>((set) => ({
  isShowInsight: false,
  setIsShowInsight: (isShowInsight: boolean) => set(() => ({ isShowInsight })),

  isLoadedPage: false,
  setIsLoadedPage: (isLoadedPage: boolean) => set(() => ({ isLoadedPage })),
}));

export default useCommonStore;
