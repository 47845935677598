import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { STATUS_CONTENT_MAP } from "constants/stripe";
import PAGES from "routes/constants";
import "styles/stripe.scss";

interface PaymentCompleteProps {
    clientSecret: string;
}

const CompletePayment: React.FC<PaymentCompleteProps> = ({ clientSecret }) => {
    const stripe = useStripe();
    const navigate = useNavigate();
    const [status, setStatus] = useState<string>("default");
    const [intentId, setIntentId] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        if (!stripe) return;

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (!paymentIntent) return;
            setStatus(paymentIntent.status);
            setIntentId(paymentIntent.id);
        });
    }, [stripe, clientSecret]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            window.history.replaceState({}, document.title, '/');
            navigate(PAGES.signup);
        }
    }, [countdown, navigate]);

    return (
        <div id="payment-status">
            <div id="status-icon" style={{ backgroundColor: STATUS_CONTENT_MAP[status].iconColor }}>
                {STATUS_CONTENT_MAP[status].icon}
            </div>
            <h2 id="status-text">{STATUS_CONTENT_MAP[status].text}</h2>
            <p className="text-gray-600 mt-4 text-center">
                Voici votre identifiant de paiement: <strong>{intentId}</strong>.
            </p>
            <p className="text-gray-600 mt-4 text-center">
                Au moindre problème, munissez vous de cet identifiant et veuillez contacter notre service client.
                Vous serez redirigé vers la page d'accueil dans {countdown} secondes...
            </p>
            <button 
                className="return-button mt-4"
                onClick={() => {
                    window.history.replaceState({}, document.title, '/');
                    navigate(PAGES.signup);
                }}
            >
                Aller à la page de création de compte
            </button>
        </div>
    );
}

export default CompletePayment;