import baseApi from "services/api/base.api";
import { useMutation } from "@tanstack/react-query";
import { createPaymentIntentEndpoint } from "constants/stripe";
import { Offer } from "types/Stripe.type";


export const useMutationPaymentIntent = () => {
    return useMutation({
        mutationFn: async (orderData: Offer) => await baseApi.post(createPaymentIntentEndpoint,orderData),
        retry: 0,
    });
};