const PAGES = {
  home: "/",
  edit: "/edit/:id",
  list: "/list",
  settings: "/settings",
  dashboard: "/dashboard/:id",
  insight: "/insight/:id",
  login: "/login",
  signup: "/signup",
  confirmationSignup: "/confirmation-signup",
  forgotPassword: "/forgot-password",
  account: "/account",

  acheter: "/acheter",
  vendre: "/vendre",
  renover: "/renover",
  reCalculate: "/re-calcul",
  controls: "/controls",
  cstb: "/cstb",
  
  //stripe
  stripe: "/stripe",
  paymentComplete: "/payment-complete",
};

export const getDashboardURL = (id: string) => {
  return PAGES.dashboard.replace(":id", id);
};

export const getInsightURL = (id: string) => {
  return PAGES.insight.replace(":id", id);
};

export default PAGES;
