import { createHashRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import App from "App";
import HomePage from "pages/home";
import ListPage from "pages/list";
import EditPage from "pages/edit";
import SettingsPage from "pages/settings";
import DashboardPage from "pages/dashboard";
import InsightPage from "pages/insight";
import ReCalCulPage from "pages/re-calcul";
import ControlPage from "pages/control";
import CSTBPage from "pages/cstb";
import LoginPage from "pages/login";
import SignUpPage from "pages/signup";
import ConfirmationSignUpPage from "pages/confirmation-signup";
import ForgotPasswordPage from "pages/forgot-password";

import PAGES from "./constants";
import { getLandingLocale } from "constants/landing";
import StripePaymentPage from "pages/stripe-payment";
import AccountPage from "pages/account";
// import { useEffect } from "react";

// function Redirect({ to }: { to: string }) {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   return null;
// }

const LandingJSON = getLandingLocale();

export const router = createHashRouter([
  {
    path: PAGES.home,
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <App />
      </QueryParamProvider>
    ),

    // loader: rootLoader,
    children: [
      {
        path: PAGES.home,
        element: (
          <HomePage
            landingLocale={LandingJSON.top}
            bannerTwo={LandingJSON.banner_2.all}
          />
        ),
      },
      {
        path: PAGES.acheter,
        element: (
          <HomePage
            isPersonaePage
            landingLocale={LandingJSON.top}
            personaeLocale={LandingJSON.personae.acheter}
            bannerTwo={LandingJSON.banner_2.acheter}
          />
        ),
      },
      {
        path: PAGES.vendre,
        element: (
          <HomePage
            isPersonaePage
            landingLocale={LandingJSON.top}
            personaeLocale={LandingJSON.personae.vendre}
            bannerTwo={LandingJSON.banner_2.vendre}
          />
        ),
      },
      {
        path: PAGES.renover,
        element: (
          <HomePage
            isPersonaePage
            landingLocale={LandingJSON.top}
            personaeLocale={LandingJSON.personae.renover}
            bannerTwo={LandingJSON.banner_2.renover}
          />
        ),
      },
      {
        path: PAGES.list,
        element: <ListPage />,
      },
      {
        path: PAGES.settings,
        element: <SettingsPage />,
      },
      {
        path: PAGES.dashboard,
        element: <DashboardPage />,
      },
      {
        path: PAGES.insight,
        element: <InsightPage />,
      },
      {
        path: PAGES.reCalculate,
        element: <ReCalCulPage />,
      },
      {
        path: PAGES.controls,
        element: <ControlPage />,
      },
      {
        path: PAGES.cstb,
        element: <CSTBPage />,
      },
      {
        path: PAGES.login,
        element: <LoginPage />,
      },
      {
        path: PAGES.signup,
        element: <SignUpPage />,
      },
      {
        path: PAGES.confirmationSignup,
        element: <ConfirmationSignUpPage />,
      },
      {
        path: PAGES.forgotPassword,
        element: <ForgotPasswordPage />,
      },
      {
        path: PAGES.stripe,
        element: <StripePaymentPage />,
      },
      {
        path: PAGES.paymentComplete,
        element: <StripePaymentPage />,
      },
      {
        path: PAGES.account,
        element: <AccountPage />,
      }
    ],
  },
  {
    path: PAGES.edit,
    element: <EditPage />,
    // loader: rootLoader,
  },
]);
