import { useEffect, useState } from 'react';
import { cognitoUserPool } from 'config';
import 'styles/account.scss';
import { getConfirmationEmail } from 'services/storages/userStorage';
import { personaeOptions, PROFILE_LOAD_ERROR, PROFILE_LOAD_SUCCESS, PROFILE_UPDATE_ERROR, PROFILE_UPDATE_SUCCESS } from 'constants/account';
import { useGetAccountQuery } from 'queries/useGetAccountQuery';
import { useAccountMutation } from 'mutations/useAccountMutation';
import { AccountData } from 'types/AccountData.type';

function AccountPage() {
    const [accountData, setAccountData] = useState<AccountData>();
    const userInfoAWS = cognitoUserPool.getCurrentUser();
    const confirmationEmail = getConfirmationEmail();
    const email = userInfoAWS?.getUsername() || confirmationEmail;
    const [snackbar, setSnackbar] = useState({
        show: false,
        message: '',
        type: 'success'
    });

    const { data: account, isSuccess: getAccountSuccess, error: fetchAccountError } = useGetAccountQuery(email)
    const { data: mutatedAccount, mutate, isSuccess: modifyAccountSuccess, error: modifyAccountError } = useAccountMutation()

    useEffect(() => {
        if (fetchAccountError) showSnackbar(PROFILE_LOAD_ERROR, "error")
        if (getAccountSuccess) showSnackbar(PROFILE_LOAD_SUCCESS, 'success')
        setAccountData(account)
    }, [account, getAccountSuccess, fetchAccountError]);

    useEffect(() => {
        if (modifyAccountError) showSnackbar(PROFILE_UPDATE_ERROR, 'error')
        if (modifyAccountSuccess) showSnackbar(PROFILE_UPDATE_SUCCESS, 'success')
    }, [mutatedAccount, modifyAccountError, modifyAccountSuccess]);

    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbar({ show: true, message, type });
        setTimeout(() => {
            setSnackbar(prev => ({ ...prev, show: false }));
        }, 3000);
    };

    const handleUpdate = async () => {
        if (!email || !accountData) return;
        mutate(accountData)
    };

    return (
        <div className="energy-account">
            {accountData && (
                <div className="energy-account__content">
                    <div className="profile-options">
                        <h2>Votre profil</h2>
                        <form id="account-form">
                            <div className="input-stack">
                                <input
                                    type="text"
                                    value={accountData.first_name}
                                    onChange={(e) => setAccountData({ ...accountData, first_name: e.target.value })}
                                    placeholder="Prénom"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    value={accountData.last_name}
                                    onChange={(e) => setAccountData({ ...accountData, last_name: e.target.value })}
                                    placeholder="Nom"
                                    className="form-input"
                                />
                            </div>
                        </form>
                        
                        {personaeOptions.map((option) => (
                            <div key={option.id} className="option-item">
                                <label>
                                    <input
                                        type="radio"
                                        name="personae"
                                        value={option.id}
                                        checked={accountData.personae === option.id}
                                        onChange={(e) => setAccountData({ ...accountData, personae: Number(e.target.value) })}
                                    />
                                    <div>
                                        <div className="option-title">{option.label}</div>
                                        <div className="option-description">{option.description}</div>
                                    </div>
                                </label>
                            </div>
                        ))}

                        <button 
                            onClick={handleUpdate}
                            className="btn-update"
                        >
                            Mettre à jour
                        </button>
                    </div>
                </div>
            )}
            <div className={`snackbar ${snackbar.type} ${snackbar.show ? 'show' : ''}`}>
                {snackbar.message}
            </div>
        </div>
    );
}

export default AccountPage;