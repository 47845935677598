export const personaeOptions = [
    {
        id: 1,
        label: 'checkDPE Achat',
        description: 'Vous êtes en train d\'acheter un bien et voulez comprendre le DPE de votre futur bien et son niveau de fiabilité'
    },
    {
        id: 2,
        label: 'checkDPE Location',
        description: 'Vous êtes propriétaire bailleur et voulez gagner 1 ou 2 lettres pour pouvoir louer votre bien'
    },
    {
        id: 3,
        label: 'checkDPE Réno',
        description: 'Vous êtes propriétaire et rénovez votre logement et vous voulez mesurer l\'impact en terme de lettre DPE'
    }
];

export const PROFILE_LOAD_ERROR = "Erreur lors du chargement du profil utilisateur."
export const PROFILE_UPDATE_ERROR = "Erreur lors de la mise à jour du profil."
export const PROFILE_UPDATE_SUCCESS = "Profil mis à jour avec succès."
export const PROFILE_LOAD_SUCCESS = "Profil chargé avec succès."